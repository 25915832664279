import { routing } from '@/i18n/routing';
import { ExtraAttributes } from '@/lib/types';
import axios from 'axios';
import { AUTHORITY_CODE } from './constants';

type SWROptions = { url: string; headers?: ExtraAttributes; params?: ExtraAttributes };

type QueryParams = Record<string, string | null | undefined | false>;

// generate query params from object
export const createQueryString = (params?: QueryParams): string => {
  const searchParams = new URLSearchParams();

  Object.entries(params || {}).forEach(([key, value]) => {
    if (value) searchParams.set(key, value);
  });

  return searchParams.toString();
};

// fetcher function for server components
export async function fetcher(url: string, locale: string = routing.defaultLocale) {
  try {
    const { data } = await axios.get(url, {
      headers: {
        language: locale,
        code: AUTHORITY_CODE,
      },
    });

    return data;
  } catch (e) {
    console.log('ERROR THROWN FROM FETCHER FN: ', e, url);
  }
}

// fetcher function for swr package(client side)
export const swrFetcher = async <T>(options: SWROptions) => {
  const { url, headers = {}, params = {} } = options;
  const { data } = await axios.get<T>(url, { headers, params });

  return data;
};

export const removeDuplicateSlashes = (str: string) => {
  return str.replace(/\/{2,}/g, '/');
};

export const removeLastSlash = (url: string) => {
  return url.endsWith('/') ? url.slice(0, -1) : url;
};

export const removeAuthoritySegment = (path: string = '') => {
  return path
    .split('/')
    .filter((segment) => segment !== AUTHORITY_CODE)
    .join('/');
};

export const createURL = (path: string) => {
  if (path?.includes('http')) return path;

  const url = path?.startsWith('/') ? path : `/${path}`;
  return removeAuthoritySegment(removeLastSlash(removeDuplicateSlashes(url)));
};

// remove duplicates from array
export function removeDuplicates(array: string[]) {
  array = array?.filter((item) => !!item);

  return [...new Set(array)];
}

export const createRandomId = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

// remove null|undefined values from object
export const removeNullUndefined = <T>(obj: T) => {
  const newObj: Partial<T> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = (obj as any)[key as keyof T];
      if (value !== null && value !== undefined) {
        newObj[key as keyof T] = value;
      }
    }
  }

  return newObj as T;
};

// canonical & alternates url generator for nextjs generateMetadata fn
export const generateCanonical = (url: string) => {
  return {
    canonical: url,
    languages: {
      ru: `/ru${url}`,
      en: `/en${url}`,
    },
  };
};

export const sanitizeInput = (text: string) => {
  if (!text) return '';

  return text
    ?.toString()
    ?.replace(/(<([^>]+)>)/gi, '')
    ?.replace(/<\s*script\b[^>]*>(.*?)<\/script>/gi, '')
    ?.replace(/on\w+="[^"]*"/gi, '')
    ?.trim();
};

export const capitalize = (txt: string) => (txt?.trim() ? `${txt?.[0].toUpperCase()}${txt.slice(1)}` : '');
