export {
  AUTHORITY_CODE,
  cyrillicLocale,
  DAYJS_LOCALES,
  DEFAULT_FAVICON,
  DEFAULT_LOCALE,
  DOMAIN,
  ENDPOINT,
  GOOGLE_TRANSLATE_LANGUAGES,
  karakalpakLocale,
  LANGUAGE_CODE_LIST,
  LOCALES,
  NAVBAR_Z_INDEX,
} from './constants';

export {
  capitalize,
  createRandomId,
  createURL,
  fetcher,
  generateCanonical,
  removeDuplicates,
  removeDuplicateSlashes,
  removeLastSlash,
  removeNullUndefined,
  sanitizeInput,
  swrFetcher,
} from './helpers';

export { getBase64 } from './server-only';
