import { StringList } from '@/lib/types';

export const AUTHORITY_CODE = process.env.NEXT_PUBLIC_AUTHORITY_CODE || '';
export const DOMAIN = process.env.NEXT_PUBLIC_DOMAIN || `https://gov.uz/${AUTHORITY_CODE}`;
export const ENDPOINT = 'https://api-portal.gov.uz';
export const LOCALES = ['oz', 'uz', 'qr', 'ru', 'en'] as string[];
export const DEFAULT_LOCALE = 'oz';

// uzbek-cyrillic localization for dayjs
export const cyrillicLocale = {
  name: 'uz',
  weekdays: 'Якшанба_Душанба_Сешанба_Чоршанба_Пайшанба_Жума_Шанба'.split('_'),
  months: 'январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь'.split('_'),
  weekStart: 1,
  weekdaysShort: 'Якш_Душ_Сеш_Чор_Пай_Жум_Шан'.split('_'),
  monthsShort: 'янв_фев_мар_апр_май_июн_июл_авг_сен_окт_ноя_дек'.split('_'),
  weekdaysMin: 'Як_Ду_Се_Чо_Па_Жу_Ша'.split('_'),
  ordinal: (n: number) => n,
  formats: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'D MMMM YYYY, dddd HH:mm',
  },
  relativeTime: {
    future: 'Якин %s ичида',
    past: '%s олдин',
    s: 'фурсат',
    m: 'бир дакика',
    mm: '%d дакика',
    h: 'бир соат',
    hh: '%d соат',
    d: 'бир кун',
    dd: '%d кун',
    M: 'бир ой',
    MM: '%d ой',
    y: 'бир йил',
    yy: '%d йил',
  },
};

// karakalpak localization for dayjs
export const karakalpakLocale = {
  name: 'qr', // name String
  weekdays: 'Екшемби_Дүйшемби_Шийшемби_Сәршемби_Пейшемби_Жума_Шемби'.split('_'), // weekdays Array
  weekdaysShort: 'Екш_Дүй_Ший_Сәр_Пей_Жум_Шем'.split('_'), // OPTIONAL, short weekdays Array, use first three letters if not provided
  weekdaysMin: 'Ек_Ду_Ши_Сә_Пе_Жу_Ше'.split('_'), // OPTIONAL, min weekdays Array, use first two letters if not provided
  weekStart: 1, // OPTIONAL, set the start of a week. If the value is 1, Monday will be the start of week instead of Sunday。
  yearStart: 4, // OPTIONAL, the week that contains Jan 4th is the first week of the year.
  months: 'Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентабрь_Октабрь_Ноябрь_Декабрь'.split('_'), // months Array
  monthsShort: 'Янв_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ноя_Дек'.split('_'), // OPTIONAL, short months Array, use first three letters if not provided
  ordinal: (n: number) => n, // ordinal Function (number) => return number + output
  formats: {
    // abbreviated format options allowing localization
    LTS: 'h:mm:ss A',
    LT: 'h:mm A',
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
    LLL: 'MMMM D, YYYY h:mm A',
    LLLL: 'dddd, MMMM D, YYYY h:mm A',
    // lowercase/short, optional formats for localization
    l: 'D/M/YYYY',
    ll: 'D MMM, YYYY',
    lll: 'D MMM, YYYY h:mm A',
    llll: 'ddd, MMM D, YYYY h:mm A',
  },
  relativeTime: {
    // relative time format strings, keep %s %d as the same
    future: '%s ишинде', // e.g. in 2 hours, %s been replaced with 2hours
    past: '%s алдын',
    s: 'бир неше секунд',
    m: 'Бир минута',
    mm: '%d минута',
    h: 'бир саат',
    hh: '%d саат', // e.g. 2 hours, %d been replaced with 2
    d: 'бир күн',
    dd: '%d күн',
    M: 'бир ай',
    MM: '%d ай',
    y: 'бир жыл',
    yy: '%d жыл',
  },
};

export const NAVBAR_Z_INDEX = 999;

// dayjs locale namings
export const DAYJS_LOCALES: StringList = {
  oz: 'uz-latn',
  uz: 'uz',
  ru: 'ru',
  en: 'en',
  qr: 'qr',
};

// languages for google translate
export const GOOGLE_TRANSLATE_LANGUAGES = [
  { label: 'Afrikaans', value: 'af' },
  { label: 'Alban', value: 'sq' },
  { label: 'Amxar', value: 'am' },
  { label: 'Arab', value: 'ar' },
  { label: 'Arman', value: 'hy' },
  { label: 'Assom', value: 'as' },
  { label: 'Aymara', value: 'ay' },
  { label: 'Bambara', value: 'bm' },
  { label: 'Bask', value: 'eu' },
  { label: 'Belarus', value: 'be' },
  { label: 'Bengal', value: 'bn' },
  { label: 'Birman', value: 'my' },
  { label: 'Bolgar', value: 'bg' },
  { label: 'Bosniya', value: 'bs' },
  { label: 'Bxojpuri', value: 'bho' },
  { label: 'Dan', value: 'da' },
  { label: 'Divexi', value: 'dv' },
  { label: 'Dogri', value: 'doi' },
  { label: 'Esperanto', value: 'eo' },
  { label: 'Eston', value: 'et' },
  { label: 'Eve', value: 'ee' },
  { label: 'Fin', value: 'fi' },
  { label: 'Fors', value: 'fa' },
  { label: 'Fransuz', value: 'fr' },
  { label: 'Friz', value: 'fy' },
  { label: 'Gaiti-kreol', value: 'ht' },
  { label: 'Galisiy', value: 'gl' },
  { label: 'Gavayi', value: 'haw' },
  { label: 'Golland', value: 'nl' },
  { label: 'Grek', value: 'el' },
  { label: 'Gruzin', value: 'ka' },
  { label: 'Guarani', value: 'gn' },
  { label: 'Gujarot', value: 'gu' },
  { label: 'Hind', value: 'hi' },
  { label: 'Igbo', value: 'ig' },
  { label: 'Ilokan', value: 'ilo' },
  { label: 'Indonez', value: 'id' },
  { label: 'Ingliz', value: 'en' },
  { label: 'Irland', value: 'ga' },
  { label: 'Island', value: 'is' },
  { label: 'Ispan', value: 'es' },
  { label: 'Italyan', value: 'it' },
  { label: 'Ivrit', value: 'iw' },
  { label: 'Kannada', value: 'kn' },
  { label: 'Katalan', value: 'ca' },
  { label: 'Kechua', value: 'qu' },
  { label: 'Konkan', value: 'gom' },
  { label: 'Koreys', value: 'ko' },
  { label: 'Korsikan', value: 'co' },
  { label: 'Krio', value: 'kri' },
  { label: 'Kurd (Kurmonji)', value: 'ku' },
  { label: 'Kurd (Sorani)', value: 'ckb' },
  { label: 'Kxosa', value: 'xh' },
  { label: 'Laos', value: 'lo' },
  { label: 'Latish', value: 'lv' },
  { label: 'Lingala', value: 'ln' },
  { label: 'Litva', value: 'lt' },
  { label: 'Lotin', value: 'la' },
  { label: 'Luganda', value: 'lg' },
  { label: 'Luksemburg', value: 'lb' },
  { label: 'Makedon', value: 'mk' },
  { label: 'Malagasi', value: 'mg' },
  { label: 'Malay', value: 'ms' },
  { label: 'Malayalam', value: 'ml' },
  { label: 'Maltiy', value: 'mt' },
  { label: 'Maori', value: 'mi' },
  { label: 'Maratxi', value: 'mr' },
  { label: 'Maytxili', value: 'mai' },
  { label: 'Meiteilon (Manipuri)', value: 'mni-Mtei' },
  { label: 'Mizo', value: 'lus' },
  { label: 'Mongol', value: 'mn' },
  { label: 'Nemis', value: 'de' },
  { label: 'Nepal', value: 'ne' },
  { label: 'Norveg', value: 'no' },
  { label: 'Odiya (Oriya)', value: 'or' },
  { label: 'Oromo', value: 'om' },
  { label: 'Ozarbayjon', value: 'az' },
  { label: 'Panjob', value: 'pa' },
  { label: 'Polyak', value: 'pl' },
  { label: 'Portugal', value: 'pt' },
  { label: 'Pushtu', value: 'ps' },
  { label: 'Qirg‘iz', value: 'ky' },
  { label: 'Qozoq', value: 'kk' },
  { label: 'Ruanda', value: 'rw' },
  { label: 'Rumin', value: 'ro' },
  { label: 'Rus', value: 'ru' },
  { label: 'Samoa', value: 'sm' },
  { label: 'Sanskrit', value: 'sa' },
  { label: 'Sebuan', value: 'ceb' },
  { label: 'Sepedi', value: 'nso' },
  { label: 'Serb', value: 'sr' },
  { label: 'Sesoto', value: 'st' },
  { label: 'Sindhi', value: 'sd' },
  { label: 'Singal', value: 'si' },
  { label: 'Slovak', value: 'sk' },
  { label: 'Sloven', value: 'sl' },
  { label: 'Somali', value: 'so' },
  { label: 'Suaxili', value: 'sw' },
  { label: 'Sundan', value: 'su' },
  { label: 'Tagal', value: 'tl' },
  { label: 'Tamil', value: 'ta' },
  { label: 'Tatar', value: 'tt' },
  { label: 'Tay', value: 'th' },
  { label: 'Telugu', value: 'te' },
  { label: 'Tigrinya', value: 'ti' },
  { label: 'Tojik', value: 'tg' },
  { label: 'Tsonga', value: 'ts' },
  { label: 'Turk', value: 'tr' },
  { label: 'Turkman', value: 'tk' },
  { label: 'Tvi', value: 'ak' },
  { label: 'Ukrain', value: 'uk' },
  { label: 'Urdu', value: 'ur' },
  { label: 'Uygʻur', value: 'ug' },
  { label: 'Valliy', value: 'cy' },
  { label: 'Venger', value: 'hu' },
  { label: 'Vyetnam', value: 'vi' },
  { label: 'Xausa', value: 'ha' },
  { label: 'Xitoy (odatiy)', value: 'zh-TW' },
  { label: 'Xitoy (soddalashgan)', value: 'zh-CN' },
  { label: 'Xmer', value: 'km' },
  { label: 'Xmong', value: 'hmn' },
  { label: 'Xorvat', value: 'hr' },
  { label: 'Yapon', value: 'ja' },
  { label: 'Yava', value: 'jw' },
  { label: 'Yidish', value: 'yi' },
  { label: 'Yoruba', value: 'yo' },
  { label: 'Zulu', value: 'zu' },
  { label: 'Shona', value: 'sn' },
  { label: 'Shotland-gel', value: 'gd' },
  { label: 'Shved', value: 'sv' },
  { label: 'Chex', value: 'cs' },
  { label: 'Chicheva', value: 'ny' },
];

// default favicon url for metadata
export const DEFAULT_FAVICON = 'https://api-portal.gov.uz/uploads/49b466f3-9b34-327c-8104-99446dfb4d49_media_.png';

// accessibility: language code list for html lang attribute
export const LANGUAGE_CODE_LIST: StringList = {
  oz: 'uz',
  uz: 'uz',
  qr: 'uz',
  ru: 'ru',
  en: 'en',
};
