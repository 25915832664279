'use client';

import { routing } from '@/i18n/routing';
import { StringList } from '@/lib/types';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { getCookie } from 'cookies-next';

const title: StringList = {
  oz: 'Xatolik yuz berdi!',
  uz: 'Хатолик юз берди!',
  qr: 'Қəтелик жүз берди!',
  ru: 'Произошла ошибка!',
  en: 'An error occurred!',
};

const description: StringList = {
  oz: 'Iltimos sahifani yangilang yoki keyinroq qayta urinib ko‘ring.',
  uz: 'Илтимос саҳифани янгиланг ёки кейинроқ қайта уриниб кўринг.',
  qr: 'Өтиниш бетти жаңаланг ямаса кейин қайта урынып көриң.',
  ru: 'Пожалуйста, обновите страницу или повторите попытку позже.',
  en: 'Please refresh the page or try again later.',
};

const btnTitle: StringList = {
  oz: 'Sahifani yangilash',
  uz: 'Саҳифани янгилаш',
  qr: 'Бетти жаңалаў ',
  ru: 'Обновите страницу',
  en: 'Refresh the page',
};

export default function GlobalError() {
  const locale = getCookie('NEXT_LOCALE') || routing.defaultLocale;
  return (
    <html>
      <head>
        <ColorSchemeScript forceColorScheme="light" />
      </head>
      <body>
        <MantineProvider forceColorScheme="light">
          <main className="grid h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{title[locale]}</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">{description[locale]}</p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <button
                  onClick={() => window.location.reload()}
                  className="rounded-md bg-slate-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                >
                  {btnTitle[locale]}
                </button>
              </div>
            </div>
          </main>
        </MantineProvider>
      </body>
    </html>
  );
}
